export default {
  required_documents: "Required Documents",
  email: "Email",
  payer_name: "Payer Name",
  offline_payment: "Offline Payment",
  reseller_user_email: "Travel agency email",
  created_by_user_email: "Registered user email",
  created_by_username: "User registration",
  required: "{label} is required",
  min_number: "{label} cannot be less than {min}",
  max_number: "{label} cannot be more than {max}",
  max_char: "{label} should not exceed {max} characters",
  unspecified_provider_error: "Unspecified ticket provider error",
  roles: "Roles",
  full_capacity: "No available seats",
  visa_tourist: "Tourist",
  visa_business: "Business",
  visa_transit: "Transit",
  visa_student: "Student",
  visa_employment: "Employment",
  visa_medical: "Medical",
  price_change: "Price change",
  tr_name: "Name in Turkish",
  compliance_lowest_price: "Compliance with the minimum price",
  compliance_highest_price: "Compliance with the maximum price",
  price: "Price",
  other_price: "Partial price",
  total_price: "Total price",
  item_from: "Item from",
  change_price_before_issue: "Change price before issuance",
  lack_provider_charge: "Insufficient balance with provider",
  flight_cancellation_by_airline: "Flight cancellation by airline",
  delay_adjustment_more_2_hours: "Delay or adjustment exceeding two hours",
  personal_reasons: "Personal reasons",
  lack_supply: "Supply shortage",
  from_date: "From date",
  to_date: "To date",
  cancel: "Cancel",
  tooman: "Dollar",
  note_text: "Notes",
  flow_description: "System notes",
  user_description: "User notes",
  submit_note: "Submit note",
  number_of: "Number of",
  select_provider: "Select provider",
  issuance_ticket: "Ticket issuance",
  tickets_issued: "Issued tickets",
  issue_failed: "Ticket issuance failed",
  issue_in_progress: "Issuance in progress",
  reissue_succeed: "Reissue succeeded",
  error_finding_user_inventory: "Error retrieving user wallet balance",
  confirm_and_submit: "Confirm and submit",
  please_wait: "Please wait",
  issue_succeed: "Issuance succeeded",
  incorrect_pnr: "Incorrect PNR code",
  reissue_in_Progress: "Reissue in progress",
  reissue_fail: "Reissue failed",
  issue_offline_require: "Offline issuance required",
  admin_offline_issue: "Admin offline ticket issuance",
  admin_issue_succeed: "Admin successful ticket issuance",
  reasons_failed_issuance: "Reasons for ticket issuance failure",
  reasons_ticket_refund: "Reasons for ticket refund",
  number_reserved_tickets: "Number of reserved tickets",
  number_tickets_issued: "Number of tickets issued",
  reject_reason: "Rejection reason",
  busy_roads: "Most booked destinations",
  net_profit: "Net profit",
  basic_information: "Basic information",
  description: "Notes",
  inventory_change: "Inventory change",
  current_inventory: "Current inventory",
  gender: "Gender",
  flight_filters: "Filters",
  user_log: "Logs",
  user_code: "User code",
  reseller_fa_name: "Reseller Arabic name",
  reseller_en_name: "Reseller English name",
  dollar: "Dollar",
  ages: "Ages",
  nationality: "Nationality",
  type_of_change: "Type of change",
  enter_the_amount: "Enter the amount in {label}",
  final_total_wallet: "Final total wallet",
  enter: "Please enter {label}",
  password: "Password",
  your_password: "Your password",
  new_password: "New password",
  repeat_new_password: "Repeat new password",
  old_password: "Current password",
  change_password: "Change password",
  recovery_password: "Password recovery",
  forgot_password: "Forgot password",
  one_time_password: "Verification code",
  one_time_password_was_sent: "Verification code has been sent",
  one_time_password_message:
    "Verification code has been sent to change the password",
  pl_your_emali: "Please enter your email",
  all: "All",
  charter: "Charter flights",
  system: "Regular flights",
  applicable_for_charter: "Applicable to charter flights",
  user_email: "User email",
  user_management: "User management",
  customer_email: "Customer email",
  modifier_email: "Data modifier email",
  modifier_name: "Data modifier name",
  port_name: "Payment gateway",
  subset_config_name: "Secondary settings classifier name",
  sign_in_with_email: "Sign in with email",
  enter_valid_email: "Enter a valid email",
  pl_your_phone_number: "Please enter your phone number",
  password_sent_to_number: "Password has been sent to the phone number",
  iata_code: "IATA code",
  request_code: "Request code",
  code: "{label} code",
  alternative_iata_code: "Alternative IATA code",
  en_name: "Name in English",
  eng_name: "Name in English",
  eng_last_name: "Last name in English",
  fa_name: "Name in Arabic",
  farsi_name: "Name in Farsi",
  turkey_name: "Name in Turkish",
  arabic_name: "Name in Arabic",
  fa_last_name: "Last name in Arabic",
  alternate_code: "Alternate code",
  before_select_image: "Before selecting the image",
  country: "Country",
  country_name: "Country name",
  destination_country: "Destination country",
  birth_country: "Birth country",
  passport_issuing_country: "Passport issuing country",
  airport_country: "Airport country",
  city: "City",
  origin_city: "Origin city",
  destination_city: "Destination city",
  airport_city: "Airport city",
  airport_fa_name: "Airport name in Farsi",
  airport_ar_name: "Airport name in Arabic",
  airport_en_name: "Airport name in English",
  airport_iata_code: "Airport IATA code",
  airport_time_zone: "Airport time zone",
  time_zone: "Time zone",
  operating_airline_code: "Operating airline code",
  marketing_airline_code: "Marketing airline code",
  o_airline_code: "O airline code",
  m_airline_code: "M airline code",
  resend_code: "Resend password",
  code_is_4_digits: "Verification code is 4 digits",
  reissue: "Reissue",
  origin: "Origin",
  origin_airport: "Origin airport",
  usd_exchange_rate: "USD exchange rate",
  enter_usd_exchange_rate: "Please enter the USD exchange rate",
  enter_as_percentage: "Enter as percentage",
  destination: "Destination",
  destination_airport: "Destination airport",
  fare_class: "Fare class",
  flight_class: "Flight class",
  load_amount: "Load amount",
  accesses: "Accesses",
  incomplete_documents: "Incomplete documents",
  sent_documents: "Sent documents",
  download_documents: "Download documents",
  kilo: "Kilogram",
  kilogram: "Kilogram",
  to_next: "Next",
  previous_stage: "Previous stage",
  next_stage: "Next stage",
  infant: "Infant",
  child: "Child",
  context: "Content",
  rule_text: "Flight rules",
  number_of_package: "Number of packages",
  optional: "Optional",
  cabin: "Cabin",
  cabin_class: "Cabin class",
  cabin_load_1: "Cabin load 1",
  cabin_load: "Cabin load",
  agency_fa_name: "Agency Arabic name",
  agency_en_name: "Agency English name",
  agency_code: "Agency code",
  agency: "Agency",
  agency_name: "Agency name",
  add_new_agency: "Add new agency",
  add_config_new_agency: "Add new agency settings",
  add_new_user: "Add new user",
  add_config: "Add settings",
  add_subconfig: "Add secondary settings",
  add_baggage: "Add baggage",
  add_new_rule: "Add new rules",
  add_detail: "Add details",
  add_exception: "Add exceptions",
  add: "Add",
  add_passenger: "Add passenger",
  deposit: "Deposit",
  withdraw: "Withdraw",
  one: "One",
  two: "Two",
  three: "Three",
  four: "Four",
  five: "Five",
  six: "Six",
  seven: "Seven",
  eight: "Eight",
  nine: "Nine",
  ten: "Ten",
  eleven: "Eleven",
  notifications: "Notifications",
  reserve: "Reserve",
  from_hour: "From Hour",
  to_hour: "To Hour",
  flight_hour: "Flight Hour",
  remain_day_to_flight: "Day Remaining to Flight",
  remain_day_from_flight_hour: "From Hour {n}, {n} Day Remaining to Flight",
  remain_day_to_flight_hour: "To Hour {n}, {n} Day Remaining to Flight",
  remain_hour_to_flight: "Hour Remaining to Flight",
  to_remain_hour_to_flight: "To Hour Remaining to Flight",
  from_remain_hour_to_flight: "From Hour Remaining to Flight",
  remain_hour_to_flight_from: "From {n} Hour Remaining to Flight",
  remain_hour_to_flight_to: "To {n} Hour Remaining to Flight",
  update_config_for: "Update Configuration for",
  update_airport: "Update Airport",
  order_update_history: "Order Update History",
  select: "{label} Select",
  select_1: "Select",
  do_you_want_provider: "Do you want the provider to",
  do_you_want: "Do you want",
  At_least_one_must_be_selected: "At least one must be selected from the above",
  package: "Package",
  submit_new_agency: "Submit New Agency",
  chartered_agencies: "Chartered Agencies",
  agencies: "Agencies",
  airline_en_name: "Airline English Name",
  airline_eng_name: "Airline English Name",
  airline_fa_name: "Airline Arabic Name",
  username: "Username",
  reseller_username: "Reseller Username",
  address: "Address",
  port_address: "Payment Gateway Address",
  error400: "Invalid Request",
  error401: "Unauthorized",
  error403: "Forbidden",
  error404: "Link Not Found",
  error500: "Error, please contact technical support",
  fare_class_1: "Fare Class (Y,C,K,M,...)",
  start_date: "Start Date",
  start_time: "Start Time",
  end_date: "End Date",
  end_time: "End Time",
  amount: "{label} Amount",
  penalty: "Penalty",
  provider_penalty_amount: "Provider Penalty Amount",
  provider_refundable_amount: "Provider Refundable Amount",
  provider_refundable_without_commission:
    "Provider Refundable Amount Without Commission",
  total_amount: "{label} Total Amount",
  final_amount: "{label} Final Amount",
  amount_payable_passenger: "Amount Payable by Passenger",
  dirham: "Dirham",
  product: "Product",
  prdouct_tax: "Product Tax",
  prdouct_markup: "Product Markup",
  guarantee: "Guarantee",
  ticket: "Ticket",
  port: "Payment Gateway",
  payable: "Payable",
  refundable: "Refundable",
  refund_information: "Refund Information",
  order: "Order",
  commission: "Commission",
  initial: "Initial",
  discount: "Discount",
  discount_comission: "Discount Commission",
  base: "Base",
  additional_services: "Additional Services",
  penalty_percentage: "Penalty Percentage",
  add_rules_penalty_percentage: "Add Rules Penalty Percentage",
  origin_currency: "Origin Currency",
  destination_currency: "Destination Currency",
  provider: "Provider",
  provider_amount: "Provider Amount",
  reference_provider: "Reference Provider",
  provider_name: "Provider Name",
  provider_code: "Provider Code",
  update_provider: "Update Provider",
  reference_airline: "Reference Airline",
  excel_output: "Excel Output",
  there_is_no_data: "No Data Available",
  not_possible_to_issue: "Not Possible to Issue",
  pl_enter_description: "Please Enter Description",
  have: "Available",
  have_not: "Not Available",
  ticket_no: "Ticket Number",
  code_sent: "Sent Code",
  ticket_went: "Departure Ticket",
  ticket_return: "Return Ticket",
  ticket_issuance_date: "{label} Ticket Issuance Date",
  from: "From",
  offline_issue: "{label} Offline Issue {label_1}",
  ticket_info: "Ticket Information",
  download: "Download {label}",
  refunded_ticket: "Refunded Tickets",
  ticket_warning: "Ticket Number Not Entered",
  from_time: "From Time",
  new_config_information: "New Configuration Information",
  flight_date: "{label} Flight Date",
  aircraft: "Aircraft",
  order_date: "{label} Order Date",
  date: "{label} Date",
  application: "Application",
  expiration: "Expiration",
  passport_expiration_date: "Passport Expiration Date",
  birth: "Birth",
  birth_date: "Birth Date",
  date_of_birth_international: "Date of Birth",
  penalty_start_time: "Penalty Start Date",
  penalty_end_time: "Penalty End Date",
  register_first_config:
    "After saving the settings for the first time, it will not be possible to change the list type, and you can save more settings based on the same list type.",
  issue: "Issue",
  penalty_text: "Penalty Texts",
  online_penalty: "Online Penalty",
  baggage_rules: "Baggage Rules",
  baggage_amount: "Baggage Amount",
  baggage_count: "Baggage Count",
  payment_amount_invalid: "Invalid Payment Amount",
  user_payment_amount: "User Payment Amount",
  cancel_rules: "Cancellation Rules",
  transaction: "Transaction",
  financial_transactions: "Financial Transactions",
  type_of_transactions: "Transaction Type",
  edit: "Edit",
  edit_user_successfully: "User Edited Successfully",
  operation_done_successfully: "Operation Completed Successfully",
  add_new_user_successfully: "New User Added Successfully",
  operation_encountered_error: "Operation Encountered an Error",
  operation_is_not_possible: "Operation is Not Possible",
  edit_user: "Edit User Data",
  edit_basic_info: "Edit Basic Information",
  sign_up: "Sign Up",
  change: "Change",
  modifier: "Modifier",
  modifier_user: "Change User",
  total: "Total",
  total_purchase: "Total Purchases",
  time: "Time",
  support: "Support",
  history_of_changes: "History of Changes",
  view_changes: "View Changes",
  change_profile_picture: "Change Profile Picture",
  item_was_found: "Item Found",
  latest: "Latest",
  reason: "Reason",
  direct: "Direct",
  Indirect: "Indirect",
  online: "Online",
  offline: "Offline",
  timing: "Timing",
  app: "App",
  website: "Website",
  pwa: "PWA",
  economy: "Economy Class",
  first_class: "First Class",
  business: "Business Class",
  explain: "Explain",
  refund_reason: "Refund Reason",
  online_refund: "Online Refund",
  refund_in_progress: "Refund in Progress",
  refund_succeeded: "Refund Succeeded",
  refund_failed: "Refund Failed",
  refund_successful: "Refund Successful",
  payment_and_online_refund: "Payment and Online Refund",
  payment_online_manual_refund: "Online Payment and Manual Refund",
  payment_and_manual_refund: "Payment and Manual Refund",
  new: "New",
  register: "Register",
  new_register: "New Register",
  register_new_airport: "Register New Airport",
  register_new_config_for: "Register New Config for",
  not_registered: "Not Registered",
  are_you_sure: "Are you sure?",
  route: "Route",
  execution: "Execution",
  creation: "Creation",
  account_creation_date: "Account Creation Date",
  account_info: "Account Information",
  exit_from_account: "Log Out from Account",
  no_information_found: "No Information Found",
  flight: "Flight",
  passenger: "Passenger",
  payment: "Payment",
  payments: "Financial Transactions",
  request: "Request",
  request_time: "Request Time",
  successfully_requset_message: "Request Successfully Received",
  passengers: "Passengers",
  recovery: "Recovery",
  adult_baggage: "Adult Baggage",
  child_baggage: "Child Baggage",
  infant_baggage: "Infant Baggage",
  deletion_message: "Are you sure you want to delete?",
  delete: "Delete",
  succeeded_payment: "Purchase Successful",
  yes: "Yes",
  no: "No",
  ok: "Ok",
  active: "Active",
  deactive: "Inactive",
  blocked: "Blocked",
  active_for_one_way: "Active for One-Way Trip",
  active_for_multi_routes: "Active for Multi-Route Trips",
  active_for_round_trip: "Active for Round-Trip",
  active_for_unpack_round_trip: "Active for Round-Trip with Available Seats",
  active_for_charter: "Active for Charter Flights",
  active_for_system: "Active for Regular Flights",
  after_flight_date: "After Flight Date",
  contract_commission: "Contract Commission",
  contract: "Contract",
  payment_successful: "Payment Successful",
  payment_failed: "Payment Failed",
  requested_for_offline_payment: "Requested for Offline Payment",
  payed: "Paid",
  not_requested: "Not Requested",
  in_progress: "In Progress",
  void: "No Penalty",
  done: "Completed",
  back_method: "Return Method",
  payment_in_progress: "Payment in Progress",
  submitted: "Submitted",
  round_trip: "Round-Trip",
  went: "One-Way",
  next: "Next",
  previous: "Previous",
  pl_at_least_add_one_item: "Please add at least one item",
  reserve_succeeded: "Reservation Succeeded",
  reserve_failed: "Reservation Failed",
  confirm_failed: "Not Confirmed",
  confirm_successful: "Confirmed",
  in_process: "Task in Progress",
  failed: "Failed",
  succeeded: "Succeeded",
  pending: "Pending Verification",
  waiting_review: "Waiting for Verification",
  reserve_in_progress: "Reservation in Progress",
  login: "Login",
  number_of_logins: "Number of Logins",
  number_of_passenger: "Number of Passengers",
  number_of_purchases: "Number of Purchases",
  is_percentage: "Percentage",
  markup: "Markup",
  markup_percentage: "Markup Percentage",
  airplane: "Airplane",
  tax: "Tax",
  amount_commission: "Commission Amount",
  businessـamount_commission: "Business Commission Amount",
  discountـamount_commission: "Discount Commission Amount",
  percentage_commission: "Commission Percentage",
  reseller: "Reseller",
  resellers: "Travel Agencies",
  resellers_list: "Travel Agencies List",
  resellers_top_up: "Top-Up List",
  register_new_reseller: "Add New Travel Agency",
  update_reseller: "Edit Travel Agency",
  add_reseller: "Add Travel Agency",
  pl_logo_image: "Please upload logo image",
  logo_image: "Logo Image",
  returning_arrival_date: "Return Arrival Time",
  returning_date: "Return Departure Time",
  arrival_date: "Arrival Date",
  departure_arrival_date: "Departure Arrival Time",
  departure_date: "Departure Date",
  order_state: "Order Status",
  issue_date: "Issue Date",
  row: "Row",
  id: "ID {label}",
  class: "Class",
  method_execution: "Execution Method",
  arrival_time: "Arrival Time",
  maximum_loss: "Maximum Loss",
  enter_maximum_loss: "Enter Maximum Loss in {label}",
  reserve_capability: "Reservation Capability",
  config_detail: "Configuration Details",
  baggage: "Baggage",
  adult: "Adult",
  state: "{label_1} Status {label}",
  confirm: "Confirm",
  state_message: "Are you sure about changing the status?",
  holding_infant: "With Infant",
  reference_commission: "Reference Commission",
  reference: "Reference",
  do: "Do?",
  the: "",
  commission_sharing_coefficient: "Commission Sharing Coefficient",
  surplus_commission_sharing_coefficient:
    "Surplus Commission Sharing Coefficient",
  new_subconfig_info: "New Subconfiguration Details",
  flight_no: "Flight Number",
  received_commission: "Received Commission",
  sale_date: "Sale Date {label}",
  departure: "Departure Date {label}",
  returning: "Return Date",
  to: "To",
  minimum_profit: "Minimum Profit",
  ensure_minimum_profit: "Ensure Minimum Profit",
  ensuring_minimum_profit: "Ensuring Minimum Profit",
  enter_minimum_profit: "Enter Minimum Profit in {label}",
  maximum_profit: "Maximum Profit",
  token: "Token",
  flight_time: "Flight Time",
  flight_from_time: "Flight From Time",
  flight_to_time: "Flight To Time",
  until_flight_time: "Until Flight Time",
  airline: "Airline",
  new_number: "New Number",
  national_code: "National ID / Citizenship Number",
  passenger_national_code: "Passenger National ID",
  passenger_type: "Passenger Type",
  order_code: "Order Code",
  mobile_number_11_digits: "Mobile number must be 11 digits",
  mobile: "Mobile",
  PNR_code: "PNR Code",
  airline_PNR_code: "Airline PNR Code",
  passport: "{label} Passport",
  passport_no_passenger: "Passenger Passport Number",
  number: "Number {label}",
  expiration_time: "Passport Expiration Date",
  issue_country: "Issue Country",
  first_name: "First Name {label}",
  last_name: "Last Name {label}",
  first_and_last_name: "First and Last Name",
  passenger_name: "Passenger Name",
  lorem_ipsum: "Dummy Content",
  ten_days: "Ten Days",
  thirty_days: "Thirty Days",
  sixty_days: "Sixty Days",
  unknown: "Unknown",
  canceled: "Cancelled",
  rejected: "Rejected",
  normal: "Normal",
  male: "Male",
  female: "Female",
  quick: "Quick",
  single: "Single Login",
  multi: "Multiple Logins",
  phone_number: "Phone Number",
  customer_phone_number: "Customer Phone Number",
  change_phone_number: "Change Phone Number",
  needed_number: "Mobile number must be 11 digits",
  needed_amount: "Amount must not be less than $50",
  amount_not_valid: "Invalid amount.",
  order_number: "Order Number",
  phone: "Contact Number",
  requset_number: "Request Number",
  sheba_number: "IBAN Account Number",
  airline_code: "Airline Code",
  airline_name: "Airline Name",
  add_airline: "Add Airline",
  add_new_airline: "Add New Airline",
  register_new_airline: "Register New Airline",
  update_airline: "Edit Airline",
  airline_marketing: "Airline Marketing",
  search_desired_airline: "Search Desired Airline",
  search: "Search",
  close: "Close",
  orders: "Orders",
  manual_order: "Manual Order",
  manual_payment: "Manual Payment",
  manual_registration_orders: "Manually Registered Orders",
  airlines: "Airlines",
  desired_airlines: "Desired Airlines",
  add_config_new_airline: "Add New Airline Configuration",
  add_config_new_route: "Add New Route Configuration",
  providers: "Providers",
  baggage_setting: "Baggage Settings",
  airports: "Airports",
  airport: "Airport",
  add_airport: "Add Airport",
  white: "White",
  black: "Black",
  white_list: "Whitelist",
  black_list: "Blacklist",
  white_list_exception: "Whitelist Exception",
  black_list_exception: "Blacklist Exception",
  subconfig_list: "Subconfiguration List",
  penalty_rules: "Penalty Rules",
  reports_sale: "Sales Reports",
  sale_representative: "Sales Representative",
  sale_channel: "Sales Channels",
  start_selling_from: "Start Selling From",
  start_selling_to: "Start Selling To",
  total_sale: "Total Sales",
  reports_passenger: "Passenger Reports",
  reports_ticket: "Ticket Reports",
  route_rules: "Destination Visa Rules",
  pricing: "Pricing",
  flight_filter: "Filter Flights",
  filter: "Filter",
  routes_filter: "Route Filter",
  filter_method: "Filter Method",
  refund: "Refund",
  new_refund_state: "New Refund State",
  no_new_state_found: "No New State Found",
  back_to_home_page: "Back to Home Page",
  back: "Back",
  dashboard: "Dashboard",
  domestic_flight: "Domestic Flight",
  international_flight: "International Flight",
  reports: "Reports",
  hotel: "Hotel",
  train: "Train",
  bus: "Bus",
  tour: "Tour",
  villa: "Villa and Residence",
  customers: "Customers",
  visa: "Visa {label}",
  transactions: "Transactions",
  transactions_list: "Transactions List",
  setting: "Settings",
  advance_setting: "Advanced Settings",
  show_advance_setting: "Show Advanced Settings",
  currency_rate: "Exchange Rate",
  currency_code: "Currency Type",
  arrival_terminal: "Arrival Terminal",
  departure_terminal: "Departure Terminal",
  base_fare: "Base Fare",
  total_fare: "Total Fare",
  add_currency_rate: "Add Exchange Rate",
  apply_for_approved_rate: "Apply Approved Rate",
  register_new_currency_rate: "Register New Exchange Rate",
  enter_currency_rate: "Enter Exchange Rate (USD)",
  percentage: "Percentage",
  monitoring: "Monitoring",
  extension: "Extension",
  list: "List",
  currency: "Currency",
  currency_amount: "Currency Amount",
  wallet_inventory: "Wallet Balance",
  user_inventory: "User Balance",
  add_user: "Add User",
  user_has_no_purchases: "User Has No Purchases",
  user_id: "User ID",
  type: "Type {label}",
  info: "Information {label}",
  complete_user_info:
    "To complete user information, please follow the steps in order.",
  order_detail_info: "Order Detail Information",
  view_the_flight_information:
    "View the flight information in this section and proceed to the next step if confirmed",
  view_the_payment_information:
    "Review the payment information in this section, and if approved, proceed to the next step",
  duration: "Duration",
  error_info: "The entered data is not correct",
  add_new_visa: "Add New Visa",
  save: "Save",
  add_note: "Add Note",
  note: "Note",
  notes: "Notes",
  current_state: "Current State",
  registrar: "Registrar",
  order_detail_info_select: "The ticket amount has been received by the user",
  user_mobile: "User Mobile Number",
  order_mobile: "Order Mobile Number",
  discount_code: "Discount Code",
  charter_system: "Charter/System",
  hotel_list: "Hotel List",
  hotel_info: "Hotel Information",
  location: "Location",
  province: "Province",
  room: "Room",
  rate: "Rating",
  users: "Users",
  user: "User",
  operation: "Operation",
  hotels: "Hotels",
  disabled_hotels: "Disabled Hotels",
  bank_account_number: "Bank Account Number",
  bank_account_info: "Bank Account Information",
  ticket_price: "Ticket Price",
  departure_flight: "Departure Flight",
  return_flight: "Return Flight",
  creation_time: "Creation Time",
  flight_to: "To",
  account_owner_fullname: "Account Owner Full Name",
  add_provider: "Add Provider",
  add_new_provider: "Add New Provider",
  gateway_name: "Gateway Name",
  gateway_type_name: "Gateway Type",
  add_gateway: "Add Gateway",
  gateway_id: "Gateway ID",
  register_new_gateway: "Register New Gateway",
  update_gateway: "Edit Gateway",
  gateways: "Gateways",
  flight_type: "Flight Type",
  PL_ENTER_YOUR_EN_FIRST_NAME: "Please enter your first name in Latin letters",
  PL_ENTER_YOUR_EN_LAST_NAME: "Please enter your last name in Latin letters",
  PL_ENTER_YOUR_FA_FIRST_NAME: "Please enter your first name in Arabic letters",
  PL_ENTER_YOUR_FA_LAST_NAME: "Please enter your last name in Arabic letters",
  PHONE_NUMBER_IS_INVALID: "Invalid phone number",
  PHONE_IS_INVALID: "The entered phone number is incorrect",
  EMAIL_IS_INVALID: "Invalid email",
  USERNAME_IS_INVALID: "Please use English letters and numbers",
  PASSPORT_NO_IS_INVALID: "Invalid passport number",
  RECEIVING_INFORMATION: "Receiving information...",
  PL_ENTER_YOUR_NATIONAL_CODE: "Please enter your national ID number",
  NATIONAL_CODE_IS_INVALID: "Invalid national ID number",
  SELECT_THE_DEPARTURE_FLIGHT: "Select the departure date",
  commercial_phone_number: "Commercial Phone Number",
  service_type: "Service Type",
  company_contact_info: "Company Contact Information",
  accounting_phone_number: "Accounting Phone Number",
  company_owner_info: "Company Owner Information",
  agency_acquirer_name: "Agent Name",
  company_location_info: "Company Location Information",
  company_district: "Company District",
  agreement_info: "Agreement Information",
  agreement_file: "Agreement File",
  select_file: "Select File",
  agreement_date: "Agreement Date",
  settlement_info: "Settlement Information",
  settlement_period: "Settlement Period",
  settlement_type: "Settlement Type",
  settlement_start_date: "Settlement Start Date",
  last_login: "Last Login Date",
  postpay_balance: "Minimum Amount",
  prepay_balance: "Remaining Amount",
  total_balance: "Total Amount",
  total_issued_tickets: "Total Issued Tickets",
  total_issued_orders: "Total Issued Orders",
  company_operation_city: "Company Location",
  reseller_Ar_name: "Reseller's Arabic Name",
  reseller_name: "Reseller Name",
  update_state_reseller: "Update Reseller Status",
  none: "None",
  provider_error: "Provider Error",
  credit_amount: "Credit Amount",
  internal_error: "Internal Error",
  other: "Others",
  agreement_expired: "Agreement Expired",
  agreement_canceled: "Agreement Canceled",
  not_settling: "Incomplete Financial Settlement",
  disable_reason: "Disable Reason",
  receiptNo: "Receipt Number",
  wallet: "Wallet",
  change_state_reason: "Reason for Status Change",
  whats_app_group: "WhatsApp Group",
  general_phone_number: "General Mobile Number",
  contacts_info: "Contact Information",
  panel_info: "Panel Information",
  panel_url: "Panel URL",
  panel_office_id: "Panel Office ID",
  panel_password: "Panel Office Password",
  panel_username: "Panel Username",
  show_link: "Show Link",
  please_type_in_english: "Please enter the name in English",
  please_type_in_arabic: "Please enter the name in Arabic",
  please_type_in_farsi: "Please enter the name in Persian",
  fare_rules: "Booking and Cancellation Rules",
  title: "Title",
  fare_rule_category_type: "Booking and Cancellation Rule Type",
  english_description: "English Notes",
  localize_description: "Arabic Notes",
  continue: "Continue",
  view: "View",
  airline_pnr: "Airline PNR",
  flight_travelers_count: "Flight Travelers Count",
  payment_method: "Payment Method",
  payment_provider_name: "Payment Gateway",
  first_origin: "Origin",
  o_city_name: "Origin City",
  first_destination: "Destination",
  d_city_name: "Destination City",
  first_departure_date: "Departure Date",
  first_departure_arrival_date: "Arrival Date",
  first_flight_type: "Flight Type",
  total_discount_of_commission_amount: "Total Travel Agency Commission",
  total_business_commission_amount: "Business Commission Amount",
  total_commission_amount: "Total Commission",
  total_base_fare: "Total Base Fare",
  total_extra_services_amount: "Total Other Prices",
  gateway: "Gateway",
  admin: "Admin",
  one_way: "One Way",
  multi_destination: "Multi-Destination",
  show_orders: "Show Orders",
  show_pricing: "Show Pricing",
  creator_user_email: "Creator User Email",
  main_user_email: "Seller Email",
  wallet_transaction_source_type: "Transaction Source",
  last_admin_topup_deposit_amount: "Last Admin Top-up Deposit Amount",
  wallet_balance_amount_before_transaction: "Wallet Balance Before Transaction",
  wallet_balance_amount_after_transaction: "Wallet Balance After Transaction",
  information: "Information",
  invalid_costs_entered: "Invalid Costs Entered",
  factor: "Invoice",
  deposit_amount: "Deposit Amount",
  supply_currency_code: "Supplier Currency Code",
  deposit_currency_code: "Deposit Currency Code",
  add_top_up: "Add Top-up",
  register_new_top_up: "Add New Top-up",
  pl_factor_file: "Please provide the invoice file",
  providers_top_up: "Provider Top-ups",
  amount_is_invalid: "The entered amount is incorrect",
  show_top_ups: "Show Top-up",
  limit_file_message:
    "You can select a maximum of one file. For a new file, first delete the previous one.",
  confirm_in_progress: "Pending Confirmation",
  refund_rejected: "Refund Rejected",
  system_issue_succeed: "Issued Successfully by System",
  checkin_date: "Check-in Date {label}",
  checkout_date: "Check-out Date {label}",
  hotel_type: "Hotel Type",
  total_discount_amount: "Total Discount",
  total_commission: "Total Commission",
  total_markup: "Total Markup",
  total_tax_amount: "Total Tax",
  total_fare_hotel: "Total Hotel Fare",
  dialing_code: "Dialing Code",
  top_up: "Top-up",
  accounting: "Accounting",
  reports_b2b: "B2B Reports",
  reports_b2c: "B2C Reports",
  reports_deposit_withdraw: "Deposit & Withdraw Reports",
  reports_receipts: "Receipts Reports",
  ticket_provider: "Ticket Provider",
  pnr: "PNR",
  provider_commission_percent: "Provider Commission Percent",
  provider_commission_amount: "Provider Commission Amount",
  percent: "Percentage {label}",
  refunded: "Refunded",
  profit: "Profit",
  fee: "Fee",
  agency_commission_percent: "Agency Commission Percent",
  agency_commission_amount: "Agency Commission Amount",
  amount_to_agency: "Amount Paid to Agency",
  amount_sales_refunded: "Sales Amount Refunded",
  purchasing: "Purchasing",
  create: "Create",
  username_ar: "User Arabic Name",
  creator: "Creator",
  BackOffice_Users_View: "Back Office Users View",
  InternationalFlight: "International Flights",
  DomesticFlight: "Domestic Flights",
  Customers_List_View: "Customers List View",
  InternationalFlight_Refund_View: "International Flight Refund View",
  InternationalFlight_RuleEngine_View: "International Flight Filters View",
  InternationalFlight_RouteRulePolicy_View: "Destination Visa Rules View",
  InternationalFlight_Reports_View: "International Flight Reports View",
  InternationalFlight_Crcn_View: "International Flight Penalties View",
  InternationalFlight_Airports_View: "International Flight Airports View",
  update_user: "Update User",
  register_new_user: "Add New User",
  initial_amount: "Initial Price",
  hotel_name: "Hotel Name",
  type_of_room: "Room Type",
  number_of_rooms: "Number of Rooms",
  check_in_date: "Check-in Date",
  check_out_date: "Check-out Date",
  length_of_residence: "Length of Stay",
  type_of_service: "Service Type",
  number_of_passengers: "Number of Passengers",
  reservatore: "Reservatore",
  reserve_date: "Reservation Date",
  cancel_succeeded: "Cancellation Succeeded",
  cancel_in_progress: "Cancellation in Progress",
  cancel_failed: "Cancellation Failed",
  cancel_reject: "Cancellation Rejected",
  amenities_list: "Amenities List",
  icon: "Icon",
  icon_image: "Icon Image",
  pl_icon_image: "Please upload the icon image",
  update_amenity: "Update Amenity",
  register_new_amenity: "Add New Amenity",
  hotel_manual_order: "Manual Hotel Registration",
  ar_name_hotel: "Hotel Arabic Name",
  eng_name_hotel: "Hotel English Name",
  fa_name_hotel: "Hotel Farsi Name",
  accommodation_type: "Accommodation Type",
  hotel_star: "Hotel Star",
  one_star: "One Star",
  two_star: "Two Stars",
  three_star: "Three Stars",
  four_star: "Four Stars",
  five_star: "Five Stars",
  region: "Region",
  places_near_hotel: "Places Near Hotel",
  hotel_amenities: "Hotel Amenities",
  image_gallery: "Image Gallery",
  about_hotel: "About Hotel",
  accommodation_conditions_and_rules: "Conditions and Rules",
  refund_rules: "Refund Rules",
  room_delivery_time: "Room Delivery Time",
  room_vacating_time: "Room Vacating Time",
  website_address: "Hotel Website",
  name_and_surname_of_hotel_coordinator: "Hotel Coordinator Name and Surname",
  coordinator_phone: "Coordinator Phone Number",
  one_case: "Child with Service",
  child_with_service: "Child with Service",
  child_without_service: "Child without Service",
  definition_of_age_groups: "Definition of Age Groups",
  rooms: "Rooms",
  add_rooms: "Add Rooms",
  register_hotel: "Register Hotel",
  meterage: "Room Dimensions",
  bed_type: "Bed Type",
  room_services: "Room Services",
  number_of_people: "Number of People",
  number_of_room: "Number of Rooms",
  image_upload: "Upload Images",
  room_name: "Room Name",
  room_amenities: "Room Amenities",
  register_room: "Register Room",
  PL_ENTER_YOUR_AR_FIRST_NAME: "Please enter your first name in Arabic",
  HotelID: "Hotel ID",
  room_list: "Room List",
  RoomID: "Room ID",
  edit_hotel: "Edit Hotel",
  delete_hotel: "Delete Hotel",
  PL_ENTER_IN_NUMBER: "Please enter the number",
  search_amenities: "Search Amenities",
  search_places_near: "Search Nearby Places",
  max_infant_age: "Maximum Infant Age",
  max_child_age: "Maximum Child Age",
  country_city_region: "Country/City/Region",
  country_city: "Country/City",
  management_rooms: "Room Management",
  hotel_image_gallery: "Hotel Image Gallery",
  config_and_sub_config: "Settings and Sub-settings",
  offline_issue_setting: "Offline Issue Settings",
  add_config_new_offline_issue: "Add New Offline Issue Settings",
  register_new_config_offline_issue: "Register New Offline Issue Settings",
  update_config_offline_issue: "Update Offline Issue Settings",
  offline_issuance_exception: "Offline Issuance Exception",
  offline_issuance: "Offline Issuance",
  popular_locations_list: "Popular Locations List",
  show_location: "Show Location",
  register_new_popular_location: "Register New Popular Location",
  update_popular_location: "Update Popular Location",
  latitude: "Latitude",
  longitude: "Longitude",
  hotel_location: "Hotel Location",
  two_decimal_validation: "The value must be up to two decimal places",
  markup_8_digit: "Markup should not be more than 8 digits",
  agreement_started: "Agreement Started",
  negative_error: "Value must not be negative",
  base_fare_validation: "Base fare is higher than total trip price",
  summary_orders: "Summary of Orders",
  contact_info_email: "Email",
  user_phone_number: "User Phone Number",
  user_fullname: "User Full Name",
  passenger_fullname: "Passenger Full Name",
  reseller_commission_percent: "Reseller Commission Percent",
  reseller_commission_amount: "Reseller Commission Amount",
  amount_to_reseller: "Amount Paid to Reseller",
  amount_to_customer: "Amount Paid to Customer",
  payment_type: "Payment Type",
  reseller_user_phone_number: "Reseller Phone Number",
  current_balance: "Current Balance",
  user_type_names: "User Type/Travel Agency",
  discount_commission_percent: "Commission Discount Percent",
  discount_commission_amount: "Commission Discount Amount",
  user_refunded: "Refunded to Customer",
  purchasing_safari: "Purchasing Al Safari",
  provided_by_airline: "Provided by Airline",
  time_and_date_departure_flight: "Departure Flight Time and Date",
  buying_amount: "Buying Amount",
  total_refundable_amount: "Total Refundable Amount",
  contact_support: "Contact Customer Support",
  cancellation_policy:
    "I have read and agree to the domestic flight cancellation policy.",
  financial_information: "Financial Information",
  name_must_same_as_username: "The name must match the username.",
  register_refund: "Register Refund",
  tracking_number: "Tracking Number",
  successful_refund_request: "Refund request registered successfully.",
  request_refund_result:
    "You will be notified of the refund request result within 24 hours via phone call.",
  select_reason_refund: "Please select the refund reason",
  pl_select_passenger: "Please select the passenger",
  pl_select_passengers: "Please select the passengers",
  pl_agree_cancellation_policy: "Please agree to the cancellation policy",
  account_number_limit: "The number must be 10 digits or more.",
  transaction_date: "Transaction Date {label}",
  landing_page: "Landing Pages",
  landing_page_image: "Main Image for Landing Page",
  faq: "FAQ",
  question: "Question",
  answer: "Answer",
  add_question: "Add New Question",
  delete_question: "Delete Question",
  landing_page_title: "Register Landing Page Information",
  image: "Image",
  add_origin_destination: "Add Origin and Destination",
  section_one_text: "Section One Text",
  section_two_text: "Section Two Text",
  add_flight: "Add Flight",
  remove_flight: "Remove Flight",
  min_image_count: "The minimum number of images is four",
  order_phone_number: "Order Phone Number",
  tour_list: "Regular Tours",
  origin_tour: "Tour Origin",
  destination_tour: "Tour Destination",
  campaign_start_date: "Tour Campaign Start Date",
  campaign_end_date: "Tour Campaign End Date",
  minimum_tour_period: "Minimum Tour Period",
  transport_type: "Transport Type",
  number_of_accommodation: "Number of Accommodation",
  tour_coordinator_name: "Tour Coordinator Name",
  state_tour: "Tour State",
  resereved: "Reserved",
  passengers_list: "Passenger List",
  management_transport: "Transport Management",
  management_accommodations: "Accommodation Management",
  edit_tour: "Edit Tour",
  delete_tour: "Delete Tour",
  normal_tour_manual_order: "Register Normal Tours",
  base_info_and_features: "Basic Information and Features",
  transportation: "Transportation",
  accommodations: "Accommodations",
  tour_category: "Tour Category",
  capacity_of_each_order: "Capacity of Each Order",
  tour_start_day: "Tour Start Day",
  tour_end_day: "Tour End Day",
  name_and_surname_of_tour_coordinator: "Tour Coordinator Name and Surname",
  day: "Day",
  person: "Person",
  other_features: "Other Features",
  about_tour: "About Tour",
  conditions_and_rules: "Conditions and Rules",
  documents_needed: "Documents Needed",
  equipment_needed: "Equipment Needed",
  insurance: "Insurance",
  tour_guide: "Tour Guide",
  airport_transfer: "Airport Transfer",
  add_next_destination_city: "Add Next Destination City",
  register_transportation_vehicle: "Register Transportation Vehicle",
  ship: "Ship",
  van: "Van",
  personal_car: "Personal Car",
  taxi: "Taxi",
  minibus: "Minibus",
  vehicle_type: "Vehicle Type",
  company: "Company",
  does_not_have: "Does Not Have",
  ipg_response: "IPG Response",
  ipg_log: "IPG Log",
  notification_sms: "Notification SMS",
  cancellation_flight: "Flight Cancellation",
  delay_in_flight: "Flight Delay",
  sms: "SMS",
  register_new_time_for_flight: "Register New Flight Time",
  departure_flight_time: "Departure Flight Time",
  return_flight_time: "Return Flight Time",
  send: "Send",
  log_sent_message: "Log Sent Message",
  send_message: "Send SMS",
  message: "SMS",
  get_message_text: "Get SMS Text",
  message_text: "SMS Text",
  message_subject: "SMS Subject",
  extra_amenities: "Extra Amenities",
  hotel_capacity: "Hotel Capacity",
  add_new_accommodation: "Add New Accommodation",
  number_of_selected_orders: "Number of Selected Orders",
  sending_message: "Are you sure you want to send the message?",
  sending_message_successfully: "Message sent successfully.",
  sending_message_error: "Error occurred while sending the message.",
  international_hotel: "International Hotel",
  domestic_hotel: "Domestic Hotel",
  arrival_date_message: "The trip duration must not exceed one day.",
  Wifi: "Wi-Fi",
  Parking: "Parking",
  BellBoy: "Bellboy",
  Medical: "Medical",
  SafeBox: "Safe Box",
  Transfer: "Transfer",
  Exchange: "Exchange",
  Sauna: "Sauna",
  Massage: "Massage",
  Pool: "Pool",
  RoomService24H: "24-Hour Room Service",
  Reception24H: "24-Hour Reception",
  Laundry: "Laundry",
  CarRent: "Car Rental",
  CoffeeShop: "Coffee Shop",
  Smoke: "Smoke",
  Jacuzzi: "Jacuzzi",
  Gym: "Gym",
  ConferenceHall: "Conference Hall",
  Disabled: "Disabled",
  Shop: "Shop",
  submit_hotel_successfully: "Hotel registered successfully.",
  submit_hotel_error: "Error occurred while registering the hotel.",
  single_bed: "Single",
  double: "Double",
  fa_name_room: "Room Name in Persian",
  ar_name_room: "Room Name in Arabic",
  eng_name_room: "Room Name in English",
  edit_hotel_successfully: "Hotel edited successfully.",
  edit_hotel_error: "Error occurred while editing the hotel.",
  submit_room_successfully: "Room registered successfully.",
  submit_room_error: "Error occurred while registering the room.",
  edit_room_successfully: "Room edited successfully.",
  edit_room_error: "Error occurred while editing the room.",
  edit_room: "Edit Room",
  new_room: "New Room",
  order_list: "Order List",
  visa_list: "Visa List",
  visa_country: "Visa Country",
  visa_types: "Visa Types",
  visa_type: "Visa Type",
  edit_visa: "Edit Visa",
  view_details: "View Details",
  famous_place: "Image of a Famous Place in This Country",
  max_size: "Maximum image size 2Mb",
  length: "Duration {label}",
  validity: "Validity",
  operation_process: "Operation Process",
  cover_image: "Cover Image",
  flag_image: "Flag Image",
  number_of_request: "Number of Requests",
  requester: "Requester",
  initial_offline_issuance_changable:
    "Initial Offline Issuance (with future price change possibility)",
  change_order_info: "Change Order Info",
  update_order: "Update Order",
  done_success: "Done",
  reports_orders: "Order Reports",
  reports_passengers: "Passenger Reports",
  customer_fullname: "Customer Full Name",
  total_profit_before_commission: "Total Profit Before Commission",
  total_profit_after_commission: "Total Profit After Commission",
  total_profit: "Total Profit",
  PL_ENTER_HOTEL_EN_NAME: "Please enter the hotel name in Latin letters",
  PL_ENTER_HOTEL_FA_NAME: "Please enter the hotel name in Persian letters",
  PL_ENTER_HOTEL_AR_NAME: "Please enter the hotel name in Arabic letters",
  PL_ENTER_ROOM_EN_NAME: "Please enter the room name in Latin letters",
  PL_ENTER_ROOM_FA_NAME: "Please enter the room name in Persian letters",
  PL_ENTER_ROOM_AR_NAME: "Please enter the room name in Arabic letters",
  minimum_select_departure_or_returning:
    "Select at least one departure or return date",
  select_departure_date: "Select Departure Date",
  message_sending_date: "Message Sending Date and Time",
  flight_date_error: "The flight date has passed.",
  queue: "In Sending Queue",
  sent: "Sent",
  failed_to_send: "Failed to Send",
  adult_price: "Adult Price",
  child_price: "Child Price",
  infant_price: "Infant Price",
  APPLY_CHANGES: "Apply Changes",
  start_tour: "Start Tour",
  accommodation: "Accommodation",
  minimum_pricing_tour:
    "Price of each car is higher for at least 3 days for one adult",
  register_tour: "Register Tour",
  room_service: "Room Service",
  price_reduction_percentage_as_infant:
    "Price Reduction Percentage for Infants",
  price_reduction_percentage_as_child:
    "Price Reduction Percentage for Children",
  markup_percent_as_one_day_more_than_n:
    "Markup Percentage for Each Additional Day More Than N Days",
  markup_percent_as_one_day_more: "Markup Percentage for Each Additional Day",
  VISA_TITLE: "Visa Title",
  VISA_TITLE_EN: "Visa Title in English",
  new_item_register: "Register New Visa",
  PLEASE_SELECT_COUNTRY_AND_COUNTRY_IMAGES:
    "Please select the country and images",
  show_with_paid_currency: "Show with Paid Currency",
  subject: "Subject",
  unread: "Unread",
  open: "Open",
  closed: "Closed",
  need_reply: "Need Reply",
  ticketing: "Customer Relationship Management",
  created_date: "Created Date",
  full_name: "Full Name",
  high: "High",
  priority: "Priority",
  operator_fullname: "Operator Full Name",
  messages: "Messages",
  write_your_message: "Write Your Message",
  attach_file: "Attach File",
  file_attached: "File Attached",
  file: "File",
  qr_code: "QR Code",
  generate_qr_code: "Generate QR Code",
  google_auth_code: "Google Auth Code",
  change_state: "Change State",
  download_file: "Download File",
  visa_duration: "Visa Duration",
  visa_entrance_times: "Entry Type",
  visa_expiry_months: "Expire Months",
  visa_issue_days: "Issue Days",
  download_x_docs: "Download {x} Docs",
  upload_result: "Upload Result",
  result: "Result",
  finalization_succeeded: "Finalization Succeeded",
  input_file: "Input File",
  metabase: "Metabase",
  promotions: "Promotions",
  offline_payment_method: "Offline Payment Method",
  office: "Office",
  user_address: "User Address",
  show_price: "Show Price",
  payment_succeed: "Payment Succeed",
};
